.aLaCarte {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  width: 90vw;

  .aLaCarte__bg {
    background: url(img/quesadilla.jpg);
    background-size: cover;
    background-position: center;
    padding: 50px;
  }

  .aLaCarte__menu {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    margin: 30px 0px 0px 0px;
  }
}
