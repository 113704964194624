@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

$middle__size: 542px;
$full__size: 700px;


@mixin middle {
  @media (min-width: #{$middle__size}) {
    @content;
  }
}

@mixin full {
  @media (min-width: #{$full__size}) {
    @content;
  }
}
.header {
  position: fixed;
  top: 0;
  padding: 10px;
  align-content: center;
  height: 8vh;
  width: 100%;
  z-index: 100;
 

  @include full {
    height: 12vh;
  }

  
  .header__title {
    position: absolute;
    font-size: 25px;
    z-index: 100;
    font-family: "open-sans";
    font-weight: 600;
    font-family: "Satisfy", cursive;
    color: #fff;

    @include full {
      font-size: 33px;
    }
  }
  
  .header__icon {
    color: #fff;
    position: absolute;
    top: 13px;
    right: 10px;
    // z-index: 100;
    cursor: pointer;
    
    @include full {
      display: none;
    }

  }
  
  .header__mainLinks {
    display: none;
    justify-content: space-around;
    align-items: center;

    @include full {
      display: flex;
    }
  }

  .header__mainLinks ul {
    display: flex;
  }
  
  .header__desktopView {
    text-decoration: none;
  }
  
  .header__desktopView li{
    margin-left: 15px;
    font-size: 33px;
    font-family: 'Satisfy', cursive;
    color: #fff;
  }

  .header__menuC {
    top: -100%;
    left: 0;
    width: 100%;
    position: fixed;
    margin-top: -20px;
    margin-bottom: 20px;
    padding: 70px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: -2px 7px 15px 3px;
    box-shadow: -2px 7px 15px 3px #ebe8e8;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    transition: 0.8s ease-out;
    cursor: pointer;
    z-index: 10;
    
    @include middle {
    }
  }
  
  li {
    margin-bottom: 10px;
    list-style: none;
  }
 
  
  .header__link1 {
    color: #fff;
    text-decoration: none;
  }
  
  .header__link {
    font-family: "Satisfy", cursive;
    font-size: 20px;
    color: #000;
    text-decoration: none;
  }
}

.header__showMenu {
  top: 20px;
  left: 0;
  width: 100%;
  position: fixed;
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 70px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: -2px 7px 15px 3px #faf2f2;
  box-shadow: -2px 7px -1px 3px #ebe8e8;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  transition: 0.5s ease-in;
  z-index: 10;
  
  @include middle {
  }
}
.header__active {
  background: linear-gradient(rgba(37, 37, 51, 0.9), rgba(37, 37, 51, 0.9));

}

.header__titleDisabled {
  display: none;
}
