@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");


.checkoutItem {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 50px;
    margin-bottom: 100px;
    font-family: 'Satisfy', cursive;
    font-size: 25px;
}