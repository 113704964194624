@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

.favorites {
    background: url(img/marble.jpg);
    background-size: cover;
    background-position: center;
    // padding: 20px;

    .favorites__title {
        display: flex;
        align-items: center;
        justify-content: center;
        // background: #fff;
        font-family: 'Satisfy', cursive;
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        padding: 50px 0px;
        text-transform: uppercase;
    }

    // .favorites__local {
    //     font-size: 14px;
    //     padding: 10px;
    //     color: #fff;
    //     background:rgba($color: #000, $alpha: .6);
    // }
    
    .favorites__container {
        display: flex;
        flex-direction: column;
        padding: 30px;
        grid-gap: 10px;
        // width: 100vw;
        // background: rgba($color: #000, $alpha: .6);
    }

    .favorites__pic1 {
        background: url(img/quesadilla.jpg);
        background-size: cover;
        background-position: center;
        padding: 50px;
    }

    .favorites__pic2 {
        background: url(img/tortilla.jpg);
        background-size: cover;
        background-position: center;
        padding: 50px;
    }
    .favorites__pic3 {
        background: url(img/tortilla2.jpg);
        background-size: cover;
        background-position: center;
        padding: 50px;
    }
    .favorites__pic4 {
        background: url(img/tortilla3.jpg);
        background-size: cover;
        background-position: center;
        padding: 50px;
    }
    .favorites__pic5 {
        background: url(img/nachos.jpg);
        background-size: cover;
        background-position: center;
        padding: 50px;
    }

    @media screen and (min-width: 440px) {
        .favorites__title {
            font-size: 30px;
        }

        .favorites__container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            height: 100vh;
        }
    }

    @media screen and (min-width: 542px) {
        .favorites__title {
           font-size: 40px;
        }
    }

}
