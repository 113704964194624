@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

$large: 700px;

@mixin largeScreen {
    @media (min-width: #{$large}) {
      @content;
    }
  }
.checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include largeScreen {
        flex-direction: row;
    }

    // If cart is empty
    .checkout__emptyCart {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .checkout__emptyCart > :first-child {
       font-size: 20px;
       font-weight: 600;
    }
    .checkout__emptyCart  h2{
       text-transform: uppercase;
    }
    
    .checkout__img {
        background-position: center;
        background-size: cover;
        height: 150px;
    }
    
    .checkout__emptyOrder {
        font-family: 'Satisfy', cursive;
        text-transform: uppercase;
        font-size: 20px;
        color: #fff;
        margin-top: 45px;
        padding: 18px;
        background: red;
    }

    // End of empty cart

    .checkout__mainContent {
        background: #740060;
        color: #fff;
        border: 1px solid #000;
        padding: 30px;

        @include largeScreen {
          margin-right: 253px;

        }
    }
    .checkout__mainContent h2{
        font-family: 'Satisfy', cursive;
        text-transform: uppercase;
        border-bottom: 1px solid #fff;
    }

    .checkout__card {
        display: flex;
        align-items: center;
        padding: 20px;
        background-color: lightgray;
    }

    // below if for the form
    .checkout__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border: 1px solid #000;
        padding: 35px;

        @include largeScreen {
         top: 0;
         position: fixed;
         transform: translate(-238px, 10px);
        }
    }

    .checkout__input {
        padding: 20px;
    }

    .checkout__total {
        margin-top: 50px;
    }

    .checkout__currency {
        padding: 20px;
        font-size: 20px;
        background-color:#b42025;
        border: 1px solid #000;
        color: #fff;
        cursor: pointer;
    }
}