.sideOrders {
    display: flex;
    flex-direction: column;
    margin-bottom: 130px;
    width: 90vw;

    .sideOrders__bg {
        background: url(img/avocado.jpg);
        background-size: cover;
        background-position: center;
        padding: 50px;
    }

    .sideOrders__card {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        margin: 30px 0px 0px 0px;
    }
}